.navWrapper {
  display: flex;
  padding-left: 8px;
  padding-top: 30px;
}

.navList {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
