/*Global styles*/
body {
  position: relative;
  max-width: 100%;
  margin: 0;

  background-color: #F4F4F7;
  font-family: var(--font-main);
  font-size: 16px;
  font-weight: 400;
}

body * {
  box-sizing: border-box;
}

button {
  font-family: var(--font-main);
}

input::placeholder {
  font-family: var(--font-main);
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.no-scroll {
  overflow: hidden;
}
