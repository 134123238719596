.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px 14px;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  &:has(input:focus) {
    border-color: #9D567F;
    box-shadow:
      inset
      0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba($color: #9D567F, $alpha: 0.2);
  }

  &:has(input:disabled) {
    border-color: #c9c9c9;
    background-color: #eaeaea;
    color: #c9c9c9;

    &::placeholder {
      color: #c9c9c9;
    }

    & span {
      color: #c9c9c9;
    }

    & input {
      border-color: inherit;
      background-color: inherit;
      color: inherit;
    }

    & svg path {
      stroke: #787878;
    }
  }

  & > svg {
    flex-shrink: 0;
  }
}

.basicInput {
  appearance: none;
  width: 100%;
  height: 100%;

  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.43px;
  color: #000000;
  outline: none;
  border: none;

  &:focus::placeholder {
    opacity: 0;
  }

  &::placeholder {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.43px;
    color: rgba(0, 0, 0, 0.4);
  }

  &:focus {
    border: none;
    outline: none;
  }
}

.primary {
  background-color: #fff;
}

.hasLeftIcon {
  padding-left: 11px;
  gap: 10px;
}

.hasLeftText {
  padding-left: 10px;
  gap: 6px;

  & input {
    padding-left: 0;
  }
}

.secondaryText {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.5);
}

.hasError {
  border: 1px solid #ff0000;

  & svg path {
    fill: rgba($color: #e50000, $alpha: 0.6);
    // stroke: #9D567F;
    transition: all 0.3s;
  }

  &:has(input:focus) {
    border: 1px solid  #ff0000;
    box-shadow: 0 0 4px 0 rgb(14 70 192 / 40%);
  }
}

.basicInput[type="number"]::-webkit-outer-spin-button,
.basicInput[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.eyeButton {
  border: none;
  border-radius: 16px;
  background-color: transparent;
  transition: all 0.3s;
  cursor: pointer;
  outline: none;

  & svg {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
