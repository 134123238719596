@import "src/style/viewport.scss";

.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 300px;
  z-index: var(--zindex-header);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  min-height: 64px;
  padding: 16px 24px;
  background-color: #24b2d1;

  @media (max-width: $vp-mobileTop) {
    justify-content: flex-start;
    min-height: 48px;
    padding: 12px 16px;
  }
}