.wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.label {
  font-family: var(--font-main);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.00938em;
  padding: 0;
  border-bottom: 2px solid #c9c9c9;
  &:has(.input:focus) {
    border-bottom: 2px solid #1976D2;
    color: #1976D2;

  }
}

.input {
  padding: 7px 10px;
  font-family: inherit;
  font-size: 24;
  border: none;

  &:focus {
    outline: none;
  }
}

.datepickerLabel {
  & div {
    width: 100%;
  }
}
