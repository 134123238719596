.wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding: 16px;
}

.button {
  padding: 6px 16px;
  border: none;
  background-color: inherit;
  user-select: none;
  font-weight: 500;
  cursor: pointer;

  &--delete {
    background-color: #D43636;
    color: #fff;
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  &--cancel {
    color: #D43636;
    outline: none;
  }
}
