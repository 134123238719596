@import "src/style/viewport";

.footerWrapper {
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-top: 12px;
  border-top: 2px solid #f4f4f7;
}

.logoutButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 8px 24px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
  transition: background-color 0.3s;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    background-color: #f4f4f7;
    color: #272727;
    transition: background-color 0.3s;
    outline: none;
  }
}
