@import "src/style/viewport";
@import "src/style/animation";

.sectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 432px;
  padding: 24px;
  border-radius: 32px;
  background-color: #fff;

  @media (max-width: $vp-mobileTop) {
    max-width: 100%;
    padding: 24px 16px 16px;
    border-radius: 0 0 24px 24px;
  }
}

.pageTitle {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  text-align: center;
  color: #000;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 24px;

  @media (max-width: $vp-mobileTop) {
    padding-bottom: 0;
  }

  &--error {
    @include shakeAnimation;
  }
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 90px;

  @media (max-width: $vp-mobileTop) {
    min-height: 76px;
  }
}

.labelText {
  display: inline-flex;
  gap: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: $vp-mobileTop) {
    font-size: 12px;
    line-height: 16px;
  }

  &::after {
    content: '*';
    font-weight: 900;
    color: #ec494f;
  }
}

.input {
  font-size: 14px;
  font-weight: 500;
}

.errorText {
  font-size: 12px;
  font-weight: 400;
  color: #ec494f;
}

.submitButton {
  padding: 12px 16px;
  border-radius: 24px;
  cursor: pointer;
  outline: none;
  background-color: #24b2d1;
  color: #fff;
  border: none;
  font-weight: 500;
  font-size: 14px;


  @media (max-width: $vp-mobileTop) {
    padding: 7px 12px;
  }

}

.requiredNotion {
  display: flex;
  gap: 4px;
  margin-left: auto;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: right;
  text-transform: uppercase;
  color: #646464;

  @media (max-width: $vp-mobileTop) {
    margin-right: auto;
    margin-bottom: 32px;
  }

  &::before {
    content: '*';
    color: #ec494f;
  }
}