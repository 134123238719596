.navListItem {
  display: flex;
}

.linkItem {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 8px 24px;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  color: #272727;
  text-decoration: none;

  transition: background-color 0.3s;

  &:hover {
    background-color: #f4f4f7;
    color: #272727;
    transition: background-color 0.3s;
  }

  &:focus {
    background-color: #f4f4f7;
    color: #272727;
    transition: background-color 0.3s;
    outline: none;
  }

  &--current {
    background-color: #f4f4f7;
    & span {
      font-weight: 700;
    }
  }
}
