@import "src/style/viewport";

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  padding: 16px 24px;
  background-color: #24b2d1;

  @media (max-width: $vp-mobileTop) {
    min-height: 48px;
    padding: 12px 16px;
  }
}

.contentWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin-top: 64px;
  padding: 48px 32px 32px;

  @media (max-width: $vp-mobileTop) {
    margin-top: 48px;
    padding: 0 0 32px 0;
  }
}