@mixin shakeAnimation {
  animation: shake 0.4s ease;

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(-6px);
    }
    40% {
      transform: translateX(6px);
    }
    60% {
      transform: translateX(-6px);
    }

    80% {
      transform: translateX(6px);
    }

    100% {
      transform: translateX(0);
    }
  }
}