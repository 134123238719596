.cell {
  display: flex;

  &--active {
    color: green;
  }
  &--off {
    color: tomato;
  }
}

