@import "src/style/viewport";

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  animation: modalReveal 0.3s ease;
}

@keyframes modalReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);

  width: 380px;
  max-height: calc(100vh - (24px * 2));
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;

  @media (max-width: $vp-mobileTop) {
    position: fixed;
    top: 24px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: calc(100vh - 24px);
    border-radius: 16px 16px 0 0;
    animation: modalReveal 0.3s ease;
  }
}

.modalWrapper.window {
  @media (max-width: $vp-mobileTop) {
    height: 100vh;
    width: 100%;
  }
}

.modalWrapper.drop {
  position: absolute;
  top: 45px;
  left: 15px;
  right: 20px;

  height: max-content;
  padding: 15px;

  border-radius: 20px;
  overflow: hidden;
  animation: modalReveal 0.3s ease;

  @media (max-width: $vp-mobileTop) {
    top: 15px;
    left: 15px;
    right: 20px;
    width: auto;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 24px;
  background-color: #fff;

  & h3 {
    line-height: 22px;
  }

  @media (max-width: $vp-mobileTop) {
    padding: 16px 16px 24px;
  }
}

.contentWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &drop {
    overflow-y: hidden;
  }
}

.closeButton {
  padding: 5px;
  background-color: inherit;
  color: inherit;
  border: none;
  cursor: pointer;

  &--drop {
    position: absolute;
    z-index: 1000;
    top: 10px;
    right: 8px;
  }

  & svg {
    width: 20px;
    height: 20px;
  }
}
